<template>
  <div class="container-scroller">
    Cerrando sesión...
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'LogoutUser',
  methods: {
    ...mapActions('Authentication', ['logout']),
    logoutUser () {
      this.logout()
        .then(() => {
          this.$router.push({ name: 'login' })
        })
        .catch(() => {
          this.logout()
          this.$router.push({ name: 'login' })
        })
    }
  },
  mounted () {
    this.logoutUser()
  }
}
</script>

<style scoped>

</style>
